//import { Chart as ChartJS, CategoryScale, LinearScale, 
//    PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'

const ChartBar = ({data_block}) => {

    if (data_block.Data.datasets.length > 0){
        console.log('Parsing data that we received')
        console.log(data_block.Data.datasets);
        var colClassName = 'col-xl-' + 3 * data_block.Width + ' col-md-' + 6 * data_block.Width;
        
        console.log('Metric. Col class: ' + colClassName)}
    else{
        console.log('No data yet');
    }
    // ----- Most probably this data will be supplied from the database readily available ---
    let tick_divisor = 1;
    let tick_suffix="";
    // -----

    Chart.register(...registerables);

    return (
        <div className= {colClassName}>
            <div className="card" style={{height:400 * data_block.Height}}>
                <h5 className="card-header px-4" >{data_block.Name}</h5>
                <div className="card-body" style={{height:50}}>
                    <Bar data = {data_block.Data} 
                        options = {{
                            borderWidth: 2,
                            maintainAspectRatio: false,
                            responsive:true,
                            spanGaps: true,
                            duration: 0,
                            scales: {
                                y:{
                                    ticks:{
                                        //Abbreviate the millions
                                        callback: function(value, index, values) {
                                            return (data_block.Prefix ?? '') + (value/tick_divisor).toFixed(0) + tick_suffix
                                        },
                                        maxTicksLimit: 6
                                    }
                                }
                            },
                            animation:{
                                duration:0
                            },
                            parsing:{
                                xAxisKey: 'x',
                                yAxisKey: 'y'
                            },
                            plugins:{
                                legend: {
                                    display: true,
                                    position: 'bottom'
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
export default ChartBar
