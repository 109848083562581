import ChartLine from "./ChartLine";
import ChartBar from "./ChartBar";

import MetricCard from "./MetricCard";

const DataVisual = ({data_block}) => {
    //console.log('Printing data_block ');
    //console.log(data_block);
    switch (data_block.Component){
        case 'Line':
            return (
                <ChartLine data_block = {data_block}/>
            ) 
        case 'Bar':
            return (
                <ChartBar data_block = {data_block} unit = {data_block.Unit}/>
            ) 
        case 'Metric':
            return (
                <MetricCard data_block = {data_block} unit = {data_block.Unit}/>
            )
        
        case 'Break':
            return (
                <div className="w-100"></div>
            )
        default:
            return <div>Don't understand why {data_block.Component}</div>

    }
}

export default DataVisual
