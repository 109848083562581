//import { useState, useEffect } from 'react';
import NumberForDisplay from './Utils';
import TablePortfolioCompanies from './TablePortfolioCompanies';
import { header_style_portfolio_companies, header_style_investment_rounds } from './TablePortfolioCompaniesSettings';

const DataPortfolio = ({data}) => {

//    const [portfolio_data] = useState('$500m');
//    const new_value = '$600m';
    console.log('Portfolio data');
    console.log(data);
    let portfolio_data = data?.PortfolioTotalNumbers[0];
    //let investment_rounds_data = data?.InvestmentRounds[0];
    
    //We import table headers from a separate table not to clutter up this module
    //const companies_data_headers = data?.PortfolioCompaniesTableHeaders
    let companies_data = data?.PortfolioCompanies
    let investment_rounds_data = data?.InvestmentRounds;
  
/*
    console.log(companies_data_headers);
    console.log(companies_data);
*/
    return (
        <div>
            <h1>
                <b style={{color:'teal'}}>Portfolio at a Glance </b>
            </h1>

            <div className="row my-2" >
                <div className="col-xl-3 col-lg-4 col-md-5">
                    <div className="card text-center border-0" style={{height:200}}>
                        <h2 className="card-title font-weight-bold" >Total Investment Volume</h2>
                        <h1 className="card-body display-1" style={{color:'hotpink'}}>
                            US${NumberForDisplay(portfolio_data?.tot_investment_volume,1)}
                        </h1>
                    </div>
                    <div className="card text-center border-0" style={{height:200}}>
                        <h4 className="card-header" >Portfolio Companies</h4>
                        <h1 className="card-body text-center mt-2 display-1" style={{color:'hotpink'}}>
                            {NumberForDisplay(portfolio_data?.tot_investment_companies,1)}
                        </h1>
                    </div>
                    <div className="card text-center rounded" style={{height:200}}>
                        <h4 className="card-header border-0" >Investment Rounds</h4>
                        <h1 className="card-body text-center mt-2 display-1" style={{height:50}}>
                            {NumberForDisplay(portfolio_data?.tot_investment_count,1)}
                        </h1>
                    </div>

                </div>
                <div className = "col-xl-4 col-md-6">
                    <h2>Portfolio Companies</h2>
                    {(data !== null) ? <TablePortfolioCompanies columns = {header_style_portfolio_companies} data = {companies_data}/>:'Loading the data..'}
                </div>
                <div className = "col-xl-5 col-md-12">
                    <h2>Investment Rounds</h2>
                    {(data !== null) ? <TablePortfolioCompanies columns = {header_style_investment_rounds} data = {investment_rounds_data}/>:'Loading the data..'}
                </div>

            </div>

        </div>
    )
}

export default DataPortfolio

//<TablePortfolioCompanies columns = {portfolio_data?.PortfolioCompaniesTableHeaders[0]} data = {portfolio_data?.PortfolioCompanies[0]}/>