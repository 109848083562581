
import  CompanyListItem from './CompanyListItem';
import Container from 'react-bootstrap/Container';


const CompaniesList = ({companies, onCompanySelect}) => {
    //const [filteredName, setFilteredname] = useState('')
    // console.log('CompaniesList: the list of companies');
    // console.log(companies);

    return (
        <Container fluid>
            <div className = 'companies-list justify-container-md-left'>
                {companies.map((company)=>
                <CompanyListItem key={company.Id} company = {company} onCompanySelect = {onCompanySelect}/>)}
            </div>
        </Container>
    )
}

export default CompaniesList
