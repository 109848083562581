import Offcanvas from "react-bootstrap/Offcanvas";
import CompaniesList from "./CompaniesList";


const Sidebar = ({show, handleClose, companies, onCompanySelect}) => {
    // console.log('Sidebar: the list of companies');
    // console.log(companies);
    return (
        <Offcanvas show = {show} onHide = {handleClose} placement='start' scroll = 'true' backdrop ='false'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Select the Company</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <CompaniesList companies = {companies} onCompanySelect = {onCompanySelect} handleClose={handleClose}/>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default Sidebar
