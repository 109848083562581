//Authorization 9
import logo from './sputnik.svg'

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import * as fd from './fetch_data';
import { useState, useEffect } from 'react';
//import reactDom from 'react-dom';

//Components
import Sidebar from './Sidebar';
import DataPortfolio from './DataPortfolio';
import DataCompany from './DataCompany';
import AboutPage from './AboutPage';
import NotFoundPage from './NotFoundPage';
//import Amplify, { Auth } from 'aws-amplify';

//Authentication
import { Authenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import components from './SignInPageCustomization'

Amplify.configure(awsconfig);

function App() {
  
  const portfolioTag = {Id: '_ALL', Name:'Portfolio Overview'};

  

  //States to track
  
  const [pageToShow, setPageToShow] = useState('GENPO');
  const [showSideBar, setSideBarShow] = useState(false);
  const [companies, setCompanies] = useState([]);
  //const [companyId, setCompanyId] = useState('');
  const [companySelected, setCompanySelected] = useState(portfolioTag);
  const [portfolio_data, setPortfolioData] = useState(null);
  const [metrics_data, setMetricsData] = useState([]);  

  const handleSideBarClose = () => setSideBarShow(false);
  const handleSideBarShow = () => setSideBarShow(true);

  const handlePortfolioOverviewShow = () => {
    console.log('Show portfolio overview');
    setCompanySelected(portfolioTag);
    setPageToShow('GENPO');
  } 
  const handlePageAboutShow = () => setPageToShow('ABOUT');

  const onCompanySelect = (company) => {
    //console.log(`Here's what we've selected ${company.Id}`);
    setSideBarShow(false);
    setCompanySelected(company);
    setPageToShow('COMMT');
  }
  //Hook on page load bring the list of companies
  useEffect(() =>{
    const getCompanies = async()=>{
      const companiesList = await fd.fetchCompanies()
      console.log('Bringing the list of the companies');
      console.log(companiesList);
      setCompanies(companiesList);
    }
    getCompanies();
  }, [])
  
  //Hook on companyId change
  useEffect(()=>{
    
    if (companySelected.Id !== '_ALL'){
      console.log('New company Id: ' + companySelected.Id);
      const getMetricsData = async()=>{
        const fetchedMetricsData = await fd.fetchMetricsData(companySelected.Id);
        setMetricsData(fetchedMetricsData);
      }
      getMetricsData();
    }
    else{
      //Get portfolio data
      const getPortfolioData = async() => {
        const fetchedPortfolioData = await fd.fetchPortfolioData();
        setPortfolioData(fetchedPortfolioData);
      }
      
      getPortfolioData();
    }
    //console.log('Fetching company data');
    //console.log(metrics_data);
  },[companySelected])

  //Router settings
  
  const PagePortfolio = () => {
    return (<DataPortfolio data = {portfolio_data}/>)
  }
  const PageMetrics = () => {
    return(<DataCompany  company={companySelected} metrics_data = {metrics_data} />)
  }
  
  const PageAbout = () => {
    return (<AboutPage/>)
  }

  const PageNotFound = (pageName) =>{
    return (<NotFoundPage link={pageName}/>)
  }

  const pages = []
  pages.GENPO = PagePortfolio;
  pages.COMMT = PageMetrics;
  pages.ABOUT = PageAbout;
  pages.PG404 = PageNotFound;
  //console.log(pages);
  
  return (
    <Authenticator hideSignUp={true} components={components}>
      {({signOut, user}) => (
        <div className="App">
          <Navbar bg='info' variant='dark' sticky='top' expand='sm' collapseOnSelect>
          <Navbar.Brand>
              <img src = {logo} alt=''style={{width:50, height:'auto', padding:8 }} />
              Sputnik Innovations
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
          <Navbar.Collapse>
            <Nav className='ml-auto'> 
              <button class="btn btn-info my-2 my-sm-8 pull-right" onClick={handlePortfolioOverviewShow}>Portfolio overview</button>
              <button class="btn btn-info my-2 my-sm-8 pull-right" onClick={handleSideBarShow}>Companies</button>
              <NavDropdown.Divider/>
              <button class="btn btn-info my-2 my-sm-8 pull-right" onClick={handlePageAboutShow}>About</button>
              <button class="btn btn-info my-2 my-sm-8 pull-right" onClick={signOut}>Sign Out</button>
            </Nav> 
            </Navbar.Collapse>

          </Navbar>
          <div className='content'>
            {(pageToShow in pages) ? pages[pageToShow]() : pages.PG404(pageToShow) }
            <Sidebar 
              show = {showSideBar} 
              handleClose={handleSideBarClose} 
              companies = {companies} 
              onCompanySelect = {onCompanySelect}/>
          </div>
        </div>
        )}
    </Authenticator>
  );
}

export default App;
/*
{companySelected.Id === '_ALL' 
? PortfolioPage()
: MetricsPage() }

*/
//