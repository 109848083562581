import NumberForDisplay from "./Utils";

const MetricCard = ({data_block}) => {
    console.log('MetricCard');
    console.log(data_block);

    //Initialize fake values
    let data_index;
    let metric_date;
    let metric_value;
    let data_block_name;
    let metric_value_str;
    var colClassName;

    try{data_index = data_block.Data?.datasets[0]?.MetricOffset;} catch{data_index = 0}
    try{metric_date = data_block.Data?.datasets[0]?.data.at(data_index).x;} catch{metric_date = 'N/A'}
    try{metric_value = data_block.Data?.datasets[0]?.data.at(data_index).y;} catch{metric_value = 0;}
    try{data_block_name = data_block.Data.datasets[0]?.label + ' as of ' + metric_date;} catch{data_block_name = 'N/A'}
    try{metric_value_str = (data_block.Prefix ?? '') + NumberForDisplay(metric_value,1);} catch{metric_value_str = 'N/A'}
    try{colClassName = 'col-xl-' + 3 * data_block.Width + ' col-md-' + 6 * data_block.Width;} catch{colClassName = 'col-xl-3 col-md-6'}

    //console.log('Metric. Col class: ' + colClassName)
    return (
/*        <div className="card" >
        <h5 className="card-header">
          {name}
        </h5>
        <h1 className="card-body text-center mt-2 display-1">
          {value}
        </h1>
      </div>
*/
        <div className={colClassName}>
            <div className="card" style={{height:200 * data_block.Height}}>
                <h5 className="card-header px-4" >{data_block_name}</h5>
                <h1 className="card-body text-center mt-2 display-1" style={{height:50}}>
                    {metric_value_str}
                </h1>

            </div>

        </div>




    )
}

export default MetricCard
//<p>{JSON.stringify(data_block?.Data?.datasets[0])}</p>