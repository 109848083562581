const NumberForDisplay = (number,decimals=0) =>{
    if (Math.abs(number) >= 1e9){
        return (number/1e9).toFixed(decimals) + 'b'
    }
    else if (Math.abs(number) >=1e6){
        return (number/1e6).toFixed(decimals) + 'm'
    }
    else if (Math.abs(number) >=1e3){
        return (number/1e3).toFixed(decimals) + 'k'
    }
    else {
        return number
    }

}

export default NumberForDisplay