let header_style={textAlign:'center', color:'midnightblue'}
export const header_style_portfolio_companies = [
    {
        Header: () => (<div style={header_style}>Id</div>), 
        accessor: 'companyId'
    }
    ,{
        Header: () => (<div style={header_style}>Name</div>), 
        accessor: 'companyName',
        Cell: (row) => (<div style={(row.value === 'TOTAL')?{fontWeight:'bold'}:{}}>{row.value}</div>)
    }
    ,{
        Header: () => (<div style={header_style}>Amount</div>), 
        accessor: 'investmentAmount',
        style:{textAlign:'right'},
        headerStyle:{
            textAlign:'center',
            fontWeight:'bold'
        },
        Cell: (row) => (<div style={{textAlign: 'right'}}>{row.value}</div>)
    }
    ,{
        Header: () => (<div style={header_style}>Last Investment</div>),  
        accessor: 'lastInvestmentDate',
        minWidth: 100,
        maxWidth: 200,
        width: 150,
        headerStyle:{
            color:'blue'
        },
        Cell: (row) => (<div style={{textAlign: 'center'}}>{row.value}</div>)
    }
]

export const header_style_investment_rounds = [
    {
        Header: () => (<div style={header_style}>Company Id</div>), 
        accessor: 'companyId'
    }
    ,{
        Header: () => (<div style={header_style}>Name</div>), 
        accessor: 'companyName',
    }
    ,{
        Header: () => (<div style={header_style}>Investment Instrument</div>), 
        accessor: 'investmentInstrument',
    }
    ,{
        Header: () => (<div style={header_style}>Pre-money Valuation</div>), 
        accessor: 'preMoney',
        style:{textAlign:'right'},
        headerStyle:{
            textAlign:'center',
            fontWeight:'bold'
        },
        Cell: (row) => (<div style={{textAlign: 'right'}}>{row.value}</div>)
    }
    ,{
        Header: () => (<div style={header_style}>Round Size</div>), 
        accessor: 'roundSize',
        style:{textAlign:'right'},
        headerStyle:{
            textAlign:'center',
            fontWeight:'bold'
        },
        Cell: (row) => (<div style={{textAlign: 'right'}}>{row.value}</div>)
    }
    ,{
        Header: () => (<div style={header_style}>Date Announced</div>),  
        accessor: 'dateAnnounced',
        minWidth: 100,
        maxWidth: 200,
        width: 150,
        headerStyle:{
            color:'blue'
        },
        Cell: (row) => (<div style={{textAlign: 'center'}}>{row.value}</div>)
    }
]