
const AboutPage = () => {
    return (
        <div>
            <h1>About this project</h1>            
            <h5>This beautiful app has been created during busy days and sleepless nights of Dec 21 and Jan 22</h5>
        </div>
    )
}

export default AboutPage
