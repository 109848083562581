import {useTable} from 'react-table';

const TablePortfolioCompanies = ({columns, data, onRowClick}) => {

    const {
        getTableProps
        ,getTableBodyProps
        ,headerGroups
        ,rows
        ,prepareRow
    } = useTable({columns, data});


    
    console.log('Table: columns and data');
    console.log(columns);
    console.log('data');
    console.log(data);
    
    if (!columns) return <div></div>
//className = 'table table-striped table-bordered table-hover'
    return (
        <table className = 'table table-striped table-bordered table-hover' {...getTableProps}>
            <thead>
                {headerGroups.map((headerGroup)=>(
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                            </th>
                    ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row,i) => {
                    prepareRow(row);
                    return(
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td style={{width:0.25}} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )

}

export default TablePortfolioCompanies
