

//const localhost = '192.168.0.151';
//const localhost = 'localhost';
//const api_url = `http://${localhost}:8090/api/`
const api_url = "https://sotvo2ebmh.execute-api.us-east-2.amazonaws.com/";


export const fetchPortfolioData = async() => {
    let res = await fetch(api_url + 'portfolio');
    let data =  await res.json();
    //console.log(`Portfolio data. Trying to reach ${api_url + 'portfolio'}`);
    if (data !== '') {
        //console.log(data);
        const dat = JSON.parse(data);
        //console.log(dat);
        return(dat)
    } else {
        return (null)
    }
    
}

export const fetchCompanies = async() =>  {
    let res = await fetch(api_url + 'companies');
    let data =  await res.json();
    console.log(`List of companies. Trying to reach ${api_url + 'companies'}`);
    if (data !== ''){
      return(data);
    }
    else{
      return({'Id':'3dgs'});
    }

  }

  export const fetchMetricsData = async(companyId) => {
    console.log('fetchMeticsdata ' + companyId);

    let sql_json_tag = 'JSON_F52E2B61-18A1-11d1-B105-00805F49916B'
    if (companyId !== '_ALL' ){
      let res = await fetch(api_url + `metrics-data?companyId=${companyId}`);
      //console.log(`Chart Data. Trying to reach ${api_server_url}`);
      let data = await res.json();
      //console.log('fetchChartData ' + companyId);
      //console.log(data[sql_json_tag]);
      //if (data[sql_json_tag] !== '') {
      //    return(JSON.parse(data[sql_json_tag]));
      //}
      //else {
      
      console.log(data);
      return(data);

      //}
      
    }
    else {
      return null;
    }
  }
