import React from 'react';
import Button from 'react-bootstrap/Button';
import './App.css';

const CompanyListItem = ({company, onCompanySelect}) => {
    return (
            <div className ='text-right'>
                <Button className = 'company-btn' 
                    variant='outline-primary'  
                    size='lg' onClick = {() => {onCompanySelect(company)}}>
                    {company.Name}
                </Button>
            </div>
    )
}

export default CompanyListItem
