import { Tabs } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';

import DataVisual from './DataVisual';

//import { useState, useEffect, useRef } from 'react';

const DataCompany = ({company, metrics_data }) => {
return(
  <div>
      <h1>
        <small style={{color:'tan'}}>{company.Id}</small> - <b style={{color: 'teal'}}>{company.Name}</b>
      </h1>
  <Tabs defaultActiveKey="metrics" id = "company-info-tab" className='mb-3'>

    <Tab eventKey="general-info" title="General Info">
      Here will be the general info
    </Tab>
    <Tab eventKey="metrics" title="Metrics">
      {(metrics_data !== null) ?
        <div className="row my-2">
          {metrics_data.map((data_block, index) => 
            (
              <DataVisual key = {index} data_block={data_block}/>
          ))}
        </div>        
      :
        <div>
          <div>No data yet</div>May be some time later<div></div>
        </div>
      }
    </Tab>
    <Tab eventKey="investment-details" title="Investment Details">
      Here will be the investment details info
    </Tab>
    <Tab eventKey="company-news" title="Company News">
      Here will be the company news
    </Tab>
    <Tab eventKey="background-materials" title="Background Materials">
      Here will be the company news
    </Tab>

  </Tabs>
  </div>  
)
}

export default DataCompany
//<DataVisual key = {index} data_block={data_block}/>
//